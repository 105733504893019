<template>
  <div class="row card-container">
    <div class="col">
      <div class="form card card-body responsive">
        <h1>パスワード変更</h1>
        <div class="form-group mx-auto">
          <label for="inputPassword">新しいパスワード</label>
          <input id="inputPassword" v-model="newPassword" class="form-control mt-2" placeholder="半角英数字・記号、8〜20文字"
                 type="password">
          <div v-show="errors['newPassword']" id="err_inputPassword" class="form-error">{{
              errors['newPassword']
            }}
          </div>
        </div>
        <div class="form-group mx-auto">
          <label for="inputPasswordConfirm">新しいパスワードの確認</label>
          <input id="inputPasswordConfirm" v-model="newPasswordConfirm" class="form-control mt-2" type="password">
          <div v-show="errors['newPasswordConfirm']" id="err_inputComfirm" class="form-error">
            {{ errors['newPasswordConfirm'] }}
          </div>
        </div>
        <div class="btn btn-primary mt-5 font-weight-bold mx-auto" v-on:click="doChange">変更
        </div>
        <div class="btn cancel mt-3 font-weight-bold mx-auto" @click="$router.push('Login')">キャンセル
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import {SetAdminResetPasswordFinish} from "jsadminclient";
import Common from "jsadminclient/common";
import {mapGetters} from "vuex";

export default {
  name: "ChangePassword",
  data() {
    return {
      newPassword: '',
      newPasswordConfirm: '',
      errors: []
    }
  },
  computed: {
    ...mapGetters(['config']),
  },
  methods: {
    async doChange(step) {
      this.errors = [];

      try {
        const api = Common.createLoginApi()
        var params = new SetAdminResetPasswordFinish()
        params.newPassword = this.newPassword
        params.newPasswordConfirm = this.newPasswordConfirm
        params.token = this.$route.query['t']

        const jsonObject = await api.loginResetPasswordFinishPostAsync(params)

        Common.showToast("パスワードが変更されました。")

        this.$router.push({name: 'Login'})
      } catch (error) {
        Common.getErrorList(error.response, this.errors)
        const isTokenError = Common.isTokenError(error.response)
        if (isTokenError) {
          this.$router.push({name: 'Login'});
        } else {
          Common.handleAPIError(error)
        }
      }
    },
  },
}

</script>

<style scoped>

.form-control {
  width: 255px;
  background-color: #F2ECF0;
  border: 1px solid #F2ECF0;
}

.cancel {
  width: 100%;
  color: #333333;
  border: none;
  background-color: #FFFFFF;
}

.cancel:active {
  color: #C95D6C;
  background-color: #FFFFFF;
}

label {
  font-size: smaller;
}

</style>
